'use client';

import { useCookieAtom } from '@haaretz/s-atoms/cookie';
import { useUserAtom } from '@haaretz/s-atoms/user';
import useAccessListByIpMutation from '@haaretz/s-client-data-hooks/useAccessListByIpMutation';
import getBaseHostname from '@haaretz/s-common-utils/getBaseHostname';
import setCookie from '@haaretz/s-cookie-utils/setCookie';
import NoSSR from '@haaretz/s-no-ssr';
import React from 'react';

function AccessListByIp() {
  const [user, updateUser] = useUserAtom('cookieValue');
  const [cookie, updateCookie] = useCookieAtom();
  const [shouldFetch, setShouldFetch] = React.useState(false);
  const { mutate: checkIp, data } = useAccessListByIpMutation();

  React.useEffect(() => {
    if (!shouldFetch) {
      const isPaying = user.userType === 'paying';

      if (!cookie.acl && (!isPaying || !cookie._htzwif)) {
        checkIp();

        const expireMs = Date.now() + 90 * 24 * 3600 * 10000; // 900 days
        const expire = new Date(expireMs);

        // init universty cookie with none
        setCookie('_htzwif', 'none', expire);

        const date = new Date();
        date.setTime(date.getTime() + 60 * 60 * 1000);

        const cookieDomain = `.${encodeURIComponent(getBaseHostname(window.location.hostname))}`;
        document.cookie = `acl=acl;expires=${date.toUTCString()};domain=${cookieDomain};path=/`;

        setShouldFetch(true);
      }
    }
  }, [checkIp, cookie._htzwif, cookie.acl, shouldFetch, user.userType]);

  React.useEffect(() => {
    if (data === true) {
      updateCookie(document.cookie);
      updateUser('login');
    } else if (data === false) {
      console.error('AccesListByIp error!');
    }
  }, [data, updateCookie, updateUser]);

  return null;
}

export default function NoSSRWrapper() {
  return (
    <NoSSR>
      <AccessListByIp />
    </NoSSR>
  );
}
