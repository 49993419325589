/**
 * A wrapper to a promise that limits it's resolution time
 * param:Promise<unknown> =  promise the promise to be resolved
 * param:number = [timeout=5000] an optional timeout, in milliseconds
 * param:string = [msgOnTimeout='operation timed out!'] an optional on timeout rejection message
 * returns a promise that either resolves in the timeframe, or rejects on timeout.
 */

export default function getBaseHostname(hostname: string) {
  const parts = hostname.split('.');
  const removedSubdomain = parts.shift();

  if (removedSubdomain === 'haaretz' || removedSubdomain === 'themarker') {
    return hostname;
  }

  const baseHostname = parts.join('.');

  return baseHostname;
}
