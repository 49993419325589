'use client';

import useLoadEvent from '@haaretz/s-use-native-application/useLoadEvent';

export default function GeoEdgeClient({ children }: React.PropsWithChildren) {
  const isLoaded = useLoadEvent();

  if (!isLoaded) return null;

  return children;
}
