import getSimplePageType from '@haaretz/s-common-utils/getSimplePageType';

import type { BaseArticleFragment } from '@haaretz/s-fragments/articles/Base.article';
import type { AuthorPageFragment } from '@haaretz/s-fragments/AuthorPage';
import type { SeoDataFragment } from '@haaretz/s-fragments/SeoData';
import type { TagPageFragment } from '@haaretz/s-fragments/TagPage';
import type { HtzUser, PageType, PaywallType, PlatformType } from '@haaretz/s-types';

type K5AProps = {
  platform: PlatformType;
  pageType: PageType;
  breadcrumbs: string[];
  title: SeoDataFragment['metaTitle'];
  renderingKind: string;
  user: HtzUser;
} & (
  | {
      authors: Pick<AuthorPageFragment, 'contentId' | 'name'>[] | null;
      paywallType: PaywallType;
      tags: Pick<TagPageFragment, 'contentId' | 'name'>[] | null;
      publishDate: BaseArticleFragment['datePublishedISO'];
      modifiedDate?: BaseArticleFragment['dateModifiedISO'];
    }
  | {
      authors?: never;
      paywallType?: never;
      tags?: never;
      publishDate?: never;
      modifiedDate?: never;
    }
);

export default function getK5aMeta({
  authors,
  platform,
  pageType,
  paywallType,
  publishDate,
  modifiedDate,
  breadcrumbs,
  tags,
  title,
  renderingKind,
  user,
}: K5AProps) {
  const type = getSimplePageType(pageType);

  let k5aMeta: K5A = {
    channel: platform,
    contentTag: [pageType],
    section: breadcrumbs?.[1] || '',
    subsection: breadcrumbs?.slice(2) || [],
    title: title || '',
    type,
    login: typeof window === 'undefined' ? false : user.userType !== 'anonymous',
    subscriber: typeof window === 'undefined' ? false : user.userType === 'paying',
  };

  if (type === 'article' && paywallType) {
    k5aMeta = {
      ...k5aMeta,
      author: authors?.map(author => author.name) || [],
      paid: paywallType === 'premium' || paywallType === 'highlight',
      paywall: renderingKind !== 'blocked' ? 'open' : 'hard',
      publishtime: publishDate,
      modifiedtime: modifiedDate || undefined,
      tag: tags?.map(tag => tag.name) || [],
    };
  }

  return k5aMeta;
}
