'use client';

import usePathchange from '@haaretz/s-common-hooks/usePathChanged';
import { useEffect, type ScriptHTMLAttributes } from 'react';

interface PropsType extends ScriptHTMLAttributes<HTMLScriptElement> {
  src: string;
}

export function onPathchange({ src, ...otherProps }: PropsType) {
  const oldScriptTag = document.querySelector(`script[src="${src}"]`);
  const newScriptTag = document.createElement('script');
  const attrs = Object.keys(otherProps || {});

  newScriptTag.setAttribute('src', src);

  attrs?.forEach(attr => {
    newScriptTag.setAttribute(attr, otherProps[attr as keyof typeof otherProps]);
  });

  oldScriptTag?.remove();
  document.head.appendChild(newScriptTag);
}

export function onUnmount(src: PropsType['src']) {
  const scriptTag = document.querySelector(`script[src="${src}"]`);
  scriptTag?.remove();
}

export default function Script3rdParty(props: PropsType) {
  usePathchange(() => onPathchange(props));

  useEffect(() => {
    return () => onUnmount(props.src);
  }, [props.src]);

  return null;
}
