'use client';
import React from 'react';

export default function NoSSR({ children }: React.PropsWithChildren<unknown>) {
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    setLoaded(true);
  }, []);

  if (!loaded) return null;

  return <>{children}</>;
}
