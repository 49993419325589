'use client';

import useUser from '@haaretz/s-atoms/user';
import usePathChanged from '@haaretz/s-common-hooks/usePathChanged';
import { siteAlias } from '@haaretz/s-consts';
import Script from 'next/script';
import React from 'react';

import type { HtzUserType } from '@haaretz/s-types';

const userTypeMapping: Record<HtzUserType, number> = {
  paying: 1,
  registered: 2,
  anonymous: 3,
};

const taboolaLoadingSet = new Set<string>();

export default function TaboolaPixelContent() {
  const user = useUser('cookieValue');

  usePathChanged((pathname: string) => {
    // NOTE: Do not use the Back button to trigger a newPageLoad notification
    if (!taboolaLoadingSet.has(pathname)) {
      taboolaLoadingSet.add(pathname);

      window._tfa = window._tfa || [];

      window._tfa.push({ notify: 'newPageLoad' });
    }
  });

  if (!user.userType) return null;

  const audienceInternalName = `${siteAlias}_${userTypeMapping[user.userType]}`;

  return (
    <>
      <Script
        id="taboola-pixel-content"
        dangerouslySetInnerHTML={{
          __html: `
            window._tfa = window._tfa || [];
            window._tfa.push({notify: 'event', name: 'page_view', id: 1063110});
            window._tfa.push({notify: 'event', name: '${audienceInternalName}', id: 1063110});
            !function (t, f, a, x) {
                  if (!document.getElementById(x)) {
                      t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
                  }
            }(document.createElement('script'),
            document.getElementsByTagName('script')[0],
            '//cdn.taboola.com/libtrc/unip/1063110/tfa.js',
            'tb_tfa_script');
          `,
        }}
      />
      <noscript>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src="https://trc.taboola.com/1063110/log/3/unip?en=page_view"
          width="0"
          height="0"
          style={{ display: 'none' }}
          alt=""
        />
      </noscript>
    </>
  );
}
