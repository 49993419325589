'use client';

import usePathChanged from '@haaretz/s-common-hooks/usePathChanged';

interface PermutiveArticlePageOnNavigationProps {
  pageString: string;
}

export default function PermutiveArticlePageOnNavigation({
  pageString,
}: PermutiveArticlePageOnNavigationProps) {
  usePathChanged(() => {
    if (typeof window.permutive?.addon === 'function') {
      window.permutive.addon('web', {
        page: pageString,
      });
    }
  });

  return null;
}
