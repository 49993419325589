'use client';

import fork from '@haaretz/l-fork.macro';
import useUser from '@haaretz/s-atoms/user';
import Script from 'next/script';

const event = fork({ default: 'HTZ payer', tm: 'TheMarker subscriber', hdc: 'HDC registered' });

export default function OutbrainObctClient() {
  const user = useUser('cookieValue');

  return (
    <Script id="outbrain-obct" strategy="lazyOnload" type="text/javascript" data-obct="true">
      {user.userType === 'paying'
        ? `window.obApi('track', '${event}')`
        : "/** not a paying user not running  obApi('track', 'HTZ payer') **/"}
    </Script>
  );
}
