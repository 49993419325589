import type { SimplePageType, PageType } from '@haaretz/s-types';

export default function getSimplePageType(pageType: PageType): SimplePageType {
  let articleType: SimplePageType = 'section';

  if (pageType.endsWith('Article')) {
    articleType = 'article';
  } else if (pageType === 'Homepage') {
    articleType = 'homepage';
  }

  return articleType;
}
