'use client';
import { usePathname } from 'next/navigation';
import React from 'react';

type ChartBeatClientProps = {
  sections: string;
  authors: string;
};
export default function ChartBeatClient({ sections, authors }: ChartBeatClientProps) {
  const pathname = usePathname();
  const [prevPathname, setPrevPathname] = React.useState(pathname);

  React.useEffect(() => {
    if (prevPathname !== pathname) {
      setPrevPathname(pathname);

      window.pSUPERFLY?.virtualPage?.({
        sections,
        authors,
        path: pathname,
      });
    }
  }, [authors, pathname, prevPathname, sections]);

  return null;
}
