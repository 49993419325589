'use client';

import useAuthors from '@haaretz/s-atoms/authors';
import usePaywallType from '@haaretz/s-atoms/paywallType';
import usePlatform from '@haaretz/s-atoms/platform';
import useRenderingKind from '@haaretz/s-atoms/renderingKind';
import useTags from '@haaretz/s-atoms/tags';
import useUser from '@haaretz/s-atoms/user';
import getK5aMeta from '@haaretz/s-page-utils/getK5aMeta';
import Script from 'next/script';

import type { PageType } from '@haaretz/s-types';

interface KilkayaClientProps {
  title: string;
  pageType: PageType;
  publishDate?: string | null;
  modifiedDate?: string | null;
  breadcrumbs: string[];
}

export default function KilkayaClient({
  pageType,
  title,
  modifiedDate,
  publishDate,
  breadcrumbs,
}: KilkayaClientProps) {
  const user = useUser('cookieValue');
  const authors = useAuthors();
  const tags = useTags();
  const platform = usePlatform();
  const renderingKind = useRenderingKind();
  const paywallType = usePaywallType();

  const k5aMeta = getK5aMeta({
    authors,
    platform,
    pageType,
    paywallType: paywallType || 'premium',
    publishDate: publishDate || '',
    modifiedDate: modifiedDate || '',
    breadcrumbs,
    tags,
    title,
    renderingKind,
    user,
  });

  return (
    <>
      <Script type="text/javascript" strategy="beforeInteractive" id="kilkaya-metadata">{`
      window.k5aMeta = JSON.parse(${JSON.stringify(k5aMeta)});


      // k5aMeta['image']="http://www.newspaper.com/foobar/target/url.png";
      // k5aMeta['section']="Article Section";
      // k5aMeta['subsection']="Article Subsection";
      // k5aMeta['author'] = "Article Author/Byline";
      // k5aMeta['tag'] = ['Article keyword','another keyword"],
      // k5aMeta['publishtime'] = "2022-09-23T02:01:27+00:00";
      // k5aMeta['paid'] = 1; //0
      // k5aMeta['paywall'] = "hard"; //open
      // k5aMeta['login'] = 1;
      // k5aMeta['subscriber'] = 1;
    `}</Script>
      <Script
        type="text/javascript"
        src="https://cl-eu6.k5a.io/668e5a53d83b2f4bdb79db32.js"
        async
        strategy="lazyOnload"
        id="kilkaya"
      />
    </>
  );
}
