'use client';

import usePathChanged from '@haaretz/s-common-hooks/usePathChanged';

export default function OutbrainPixelOnNavigation() {
  usePathChanged(() => {
    if (typeof window.obApi === 'function') {
      window.obApi('track', 'PAGE_VIEW');
    }
  });

  return null;
}
