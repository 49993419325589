'use client';
import getBaseHostname from '@haaretz/s-common-utils/getBaseHostname';
import checkProxyHostname from '@haaretz/s-url-utils/checkProxyHostname';
import { useMutation } from '@tanstack/react-query';

export default function useAccessListByIpMutation() {
  // NOTE: this hook must be wrapped with NoSSR
  const isProxyServer = checkProxyHostname(window.location.hostname);

  const searchParams = new URLSearchParams({
    isProxyServer: String(isProxyServer),
    proxyDomain: `.${encodeURIComponent(getBaseHostname(window.location.hostname))}`,
  });

  const url = `//${document.location.hostname}/srv/ipAcl`;

  return useMutation(
    ['all', url, searchParams?.toString()].filter(Boolean),
    async () => {
      try {
        const res = await fetch(`${url}${searchParams ? `?${searchParams.toString()}` : ''}`, {
          method: 'GET',
          credentials: 'include',
        });
        return res.status === 200;
      } catch (error) {
        console.error(error);

        return false;
      }
    },
    {
      useErrorBoundary: false,
      cacheTime: 0,
      retry: false,
    }
  );
}
