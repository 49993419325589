'use client';

import usePathChanged from '@haaretz/s-common-hooks/usePathChanged';

export default function FacebookPixelOnNavigation() {
  usePathChanged(() => {
    if (typeof window.fbq === 'function') {
      window.fbq('track', 'PageView');
    }
  });

  return null;
}
