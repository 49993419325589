'use client';

import discardNullableValues from '@haaretz/s-common-utils/discardNullableValues';
import useOnce from '@haaretz/s-use-once';

interface GAConfigProps {
  gaId: string;
  configAtts: {
    page_location?: string;
    article_id?: string;
    author_name?: string | null;
  };
}

export default function GAConfig({ gaId, configAtts }: GAConfigProps) {
  useOnce(
    () => {
      window.gtag(
        'config',
        gaId,
        discardNullableValues({
          ...configAtts,
          ...(window.__HTZ?.userType ? { reader_type: window.__HTZ.userType } : {}),
        })
      );
    },
    !!(configAtts && gaId)
  );

  return null;
}
