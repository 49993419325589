'use client';

import fork from '@haaretz/l-fork.macro';
import useUser from '@haaretz/s-atoms/user';
import usePathChanged from '@haaretz/s-common-hooks/usePathChanged';
import { siteAlias } from '@haaretz/s-consts';
import Script from 'next/script';
import React from 'react';

import type { HtzUserType } from '@haaretz/s-types';

const obUserTypeMapping: Record<HtzUserType, number> = {
  paying: 1,
  registered: 2,
  anonymous: 3,
};

export default function OutbrainPixelContent() {
  const user = useUser('cookieValue');

  usePathChanged(() => {
    if (typeof window.obApi === 'function') {
      window.obApi('track', 'PAGE_VIEW');
    }
  });

  if (!user.userType) return null;

  const audienceInternalName = `${siteAlias} ${obUserTypeMapping[user.userType]}`;
  const outbrainPixelId = fork({
    default: '0043ec8d5df8e77cd469b8d8accc8527a9',
    tm: '004560e91dfb180af0ae56237c73f87ef5',
    hdc: '0082f5489b562b2a396b965a1fd7391478',
  });

  return (
    <Script
      data-obct
      strategy="lazyOnload"
      id="outbrain-pixel-content"
      type="text/javascript"
      dangerouslySetInnerHTML={{
        __html: `
          /** DO NOT MODIFY THIS CODE**/
          !function(_window, _document) {
            var OB_ADV_ID = '${outbrainPixelId}';
            if (_window.obApi) {
              var toArray = function(object) {
                return Object.prototype.toString.call(object) === '[object Array]' ? object : [object];
              };
              _window.obApi.marketerId = toArray(_window.obApi.marketerId).concat(toArray(OB_ADV_ID));
              return;
            }
            var api = _window.obApi = function() {
              api.dispatch ? api.dispatch.apply(api, arguments) : api.queue.push(arguments);
            };
            api.version = '1.1';
            api.loaded = true;
            api.marketerId = OB_ADV_ID;
            api.queue = [];
            var tag = _document.createElement('script');
            tag.async = true;
            tag.src = '//amplify.outbrain.com/cp/obtp.js';
            tag.type = 'text/javascript';
            var script = _document.getElementsByTagName('script')[0];
            script.parentNode.insertBefore(tag, script);
          }(window, document);

          obApi('track', '${audienceInternalName}');
          `,
      }}
    />
  );
}
