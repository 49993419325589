'use client';

import usePathChanged from '@haaretz/s-common-hooks/usePathChanged';

const taboolaLoadingSet = new Set<string>();

export default function TaboolaOnNavigation() {
  usePathChanged((pathname: string) => {
    // NOTE: Do not use the Back button to trigger a newPageLoad notification
    if (!taboolaLoadingSet.has(pathname)) {
      taboolaLoadingSet.add(pathname);

      window._tfa = window._tfa || [];

      window._tfa.push({ notify: 'newPageLoad' });
    }
  });

  return null;
}
